import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from "react-toastify";
import { Globalvar } from '../components/global';
import NextArrow from '../images/next-arrow.svg'
import TicketImg1 from '../images/ticket-img1.svg'
import TicketImg2 from '../images/ticket-img2.svg'
import TicketImg3 from '../images/ticket-img3.svg'
import LogoWhite from '../images/mancity-horizontal-dark.png'
import LogoWhites from '../images/mancity-horizontal-white.png'
import Cross from '../images/cross.svg'
import FreeEntryIcon from '../images/free-entry-icon.svg'
import secureLocalStorage from 'react-secure-storage';
import axios from "axios";
import apiClient from '../api.config';
import useDocumentTitle from '../components/useDocumentTitle';
import StripeCheckout from 'react-stripe-checkout';
import StripeForm from '../components/stripeCheckout';
import { loadStripe } from "@stripe/stripe-js";
import Modal from 'react-bootstrap/Modal';
import { Elements } from "@stripe/react-stripe-js";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Moment from 'moment';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MultiStepProgressBar from '../components/multipStepProgressBar/MultiStepProgressBar';
import { Helmet } from 'react-helmet';
import Logo from '../images/mancity-horizontal-white.png'
import LogoDark from '../images/mancity-horizontal-dark.png'
import { addToCart, completeRegistration, initiateCheckout } from '../utils/meta/metaPixelEvent';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function Signup() {
    useDocumentTitle('Sign Up')
    const [scroll, setScroll] = useState(false);
    const [DrawData, setDrawData] = useState([]);
    const userData = secureLocalStorage.getItem('LogObject');
    const [Freemodal, setFreemodal] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [dob, setDob] = useState('');
    const [age, setAge] = useState(0);
    const [SendDate, setSendDate] = useState();
    const [gender, setGender] = useState(1);
    const [fullName, setFullName] = useState('');
    const [userPhoneNumber, setUserPhoneNumber] = useState('+44 ');
    const [personalDetails, setPersonalDetails] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState(false);
    const [SubscriptionPlan, setSubscriptionPlan] = useState(false);
    const [isActive1, setIsActive1] = useState(true);
    const [isActive2, setIsActive2] = useState(false);
    const [isActive3, setIsActive3] = useState(false);
    const [isActive4, setIsActive4] = useState(false);
    const [amount, setAmount] = useState(5);
    const [entries, setEntries] = useState(1);
    const [consent1, setConsent1] = useState(false);
    const [consent2, setConsent2] = useState(false);
    const [consent3, setConsent3] = useState(false);
    const [oneShow, setOneShow] = useState(false);
    const [isStripe, setStripe] = useState(false);
    const [isoneoff, setIsoneoff] = useState(false);
    const [cityModal, setCityModal] = useState(false);
    const [isCityModalUsed, setIsCityModalUsed] = useState(false);
    const [UserID, setUserId] = useState(secureLocalStorage.getItem('LogObject') ? secureLocalStorage.getItem('LogObject').userID : '');
    const navigate = useNavigate();

    const [startDate, setStartDate] = useState(null);

    const handleDateChange = (date) => {
        setStartDate(date);
        setUserManCityData({ ...userManCityData, dob: date });
    };


    // const [userManCityData, setUserManCityData] = useState({
    //     firstName: 'sunny123',
    //     lastName: 'dev123',
    //     email: 'prajapatisunny@gmail.com',
    //     username: 'sTest',
    //     countryCode: '22',
    //     addressLine1: 'a1',
    //     addressLine2: 'a2',
    //     addressLine3: 'a3',
    //     city: 'test',
    //     zip: '123',
    //     mobile: '12347678',
    //     gender: '1',
    // });


    const [userManCityData, setUserManCityData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        dob: '',
    });

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);



    const siteId = process.env.REACT_APP_SITE_ID;
    const APIURL = process.env.REACT_APP_API_URL;

    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    function hideModal() {
        setOneShow(false);
    }
    Globalvar.amount = 10;
    Globalvar.entries = 2;

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 10);
        });
        axios.get(APIURL + '/sites/' + siteId + '/draws').then(res => {

            let alldraw = res.data.content;
            let activeDraw = res.data.content.filter(item => item.drawIsClosed != 1);
            let selectedDraw = {};
            if (activeDraw.length > 0) {
                selectedDraw = activeDraw[0]
            } else {
                selectedDraw = alldraw[0];
            }
            setDrawData(selectedDraw);
        });
    }, []);

    const handleClick = (param, value, tickets) => {

        switch (param) {
            case 'first':
                setAmount(value);
                Globalvar.amount = value;
                Globalvar.entries = tickets;
                secureLocalStorage.setItem('amount', value);
                secureLocalStorage.setItem('entries', tickets);
                setEntries(tickets)
                setIsActive1(true);
                setIsActive2(false);
                setIsActive3(false);
                setIsActive4(false);
                return;
            case 'second':
                setAmount(value);
                Globalvar.amount = value;
                Globalvar.entries = tickets;
                secureLocalStorage.setItem('amount', value);
                secureLocalStorage.setItem('entries', tickets);
                setEntries(tickets)
                setIsActive1(false);
                setIsActive2(true);
                setIsActive3(false);
                setIsActive4(false);
                return;
            case 'third':
                setAmount(value);
                Globalvar.amount = value;
                Globalvar.entries = tickets;
                secureLocalStorage.setItem('amount', value);
                secureLocalStorage.setItem('entries', tickets);
                setEntries(tickets)
                setIsActive1(false);
                setIsActive2(false);
                setIsActive3(true);
                setIsActive4(false);
                return;
            case 'fourth':
                setAmount(value);
                Globalvar.amount = value;
                Globalvar.entries = tickets;
                secureLocalStorage.setItem('amount', value);
                secureLocalStorage.setItem('entries', tickets);
                setEntries(tickets)
                setIsActive1(false);
                setIsActive2(false);
                setIsActive3(false);
                setIsActive4(true);
                return;
            default:
                setEntries(11)
                setAmount(10);
                setIsActive1(false);
                setIsActive2(true);
                setIsActive3(false);
                setIsActive4(false);
                return;
        }
    };

    const currency = 'GBP';
    const value = amount;

    async function CheckValidity() {
        //WithAPI Validation
        if (email.trim().length === 0) {
            toast.error(`Please Enter Your Email`);
        } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            toast.error(`Invalid Email`);
        } else if (password.trim().length === 0) {
            toast.error("Please Enter your password ")
        } else {
            try {
                const response = await toast.promise(apiClient.post(
                    `${process.env.REACT_APP_API_URL}/users/email`,
                    {
                        userEmail: email,
                        userPassword: password
                    },
                    {
                        withCredentials: true,
                        credentials: "same-origin"
                    }
                ), {
                    pending: 'Please wait...',
                }
                );
                if (response.data.status === true && response.data.error === null && response.data.content.length != 0) {
                    toast("You already have an account on City in the Community kindly login", { type: "success", autoClose: false });
                } else {
                    toSubscriptionPlan(true);
                }
            } catch (error) {
                console.log(error?.response?.data?.error);
                if (error?.response?.data?.error?.validationErrors.length) {
                    //Validation Errors
                    for (let index = 0; index < error?.response?.data?.error?.validationErrors.length; index++) {
                        const err = error?.response?.data?.error?.validationErrors[index];
                        toast.warn(err.msg);
                    }
                } else {
                    toast.error(`${error.message}`);
                }
            }
        }
    }
    function dateSet(value, event) {
        let newValue = value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
        const dayOrMonth = (index) => index % 2 === 1 && index < 4;
        const output = newValue.split('').map((v, i) => dayOrMonth(i) ? v + '/' : v).join('');
        event.target.value = output.slice(0, 10);
        let dt = output.split('/')
        var date = new Date(`${dt[2]} ${dt[1]} ${dt[0]}`);
        if (date != 'Invalid Date') {
            setSendDate(`${dt[2]}-${dt[1]}-${dt[0]}`)
        }
        setDob(output.slice(0, 10));

        var today = new Date();
        var birthDate = new Date(date);  // create a date object directly from `dob1` argument
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        console.log('Today :' + today);
        console.log('Input val :' + birthDate);

        console.log(age_now);
        setAge(age_now);
    }

    function toSubscriptionPlan() {
        setPersonalDetails(false);
        setPaymentDetails(false);
        setCityModal(false);
        setSubscriptionPlan(true);
        setPage("1");
    }
    function toPersonalDetails() {
        if (isCityModalUsed) {
            toPaymentDetailsWithManCity();
        } else {
            setPaymentDetails(false);
            setSubscriptionPlan(false)
            setPersonalDetails(true);
            setPage("2");
        }

        // Data Layer Push for Add to Cart event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'add_to_cart',
            'ecommerce': {
                'user_id': userData?.userID ? userData?.userID : 'Guest User',
                'user_email': userData?.email ? userData?.email : 'Guest User',
                'items': [{
                    'item_name': DrawData.drawName,
                    'item_id': DrawData.drawID,
                    'price': amount,
                    'item_brand': 'Sport',
                    'item_category': DrawData.drawCategoryID === 1 ? 'Fixed Cash Prize' : DrawData.drawCategoryID === 2 ? 'Split Cash Prize' : DrawData.drawCategoryID === 3 ? 'Experiential' : DrawData.drawCategoryID === 4 ? 'Hybrid' : '',
                    'item_variant': !isoneoff ? 'Subscription' : 'One-off',
                    'quantity': entries
                }]
            }
        });

        addToCart(currency, value);
    }
    async function toPaymentDetails() {
        const fn = fullName.split(" ");
        if (fullName === '') {
            toast.error(`Please Enter Your valid Full Name`);
        } else if (!fn[0] || fn[0] === '') {
            toast.error(`Please Enter Your First and last Name`);
        } else if (!fn[1] || fn[1] === '') {
            toast.error(`Please Enter Your last Name`);
        } else if (userPhoneNumber.length < 5) {
            toast.error(`Please Enter Your Phone Number`);
        } else {
            try {
                console.log('userManCityData.dob', userManCityData.dob);
                const response = await toast.promise(apiClient.post(
                    `${process.env.REACT_APP_API_URL}/users`,
                    {
                        "userFirstName": fn[0],
                        "userSurname": fn[1] ? fn[1] : "_",
                        "userEmail": email,
                        "userPassword": password,
                        // "userDateOfBirth": moment(new Date(userManCityData.dob)).format("YYYY-MM-DD"),
                        "userAddressLine1": "Address 1",
                        "userPhoneNumber": userPhoneNumber,
                        "userAddressLine2": "Address 2",
                        "userAddressCountryLocaleID": 77,
                        "userAddressPostcode": "POS COD",
                        "userRoleID": 1,
                        "userGender": gender,
                        "userLanguageID": 51,
                        "userAccountApproved": 1,
                        "userOptInMarketingPFW": 0,
                        "userMeta": {
                            "Website": "City in the Community"
                        },
                        "siteID": siteId
                    },
                    {
                        withCredentials: true,
                        credentials: "same-origin"
                    }
                ), {
                    pending: 'Please wait...',
                }
                );
                if (response.data.status === true && response.data.error === null) {
                    toast("Successfully Created Account", { type: "success" });
                    console.log('response', response);
                    secureLocalStorage.setItem("LogObject", { userID: response.data.content.userID, email: email, loggedin: true, user: response.data.content })
                    setUserId(response.data.content.userID);
                    setPage("3");
                    setPersonalDetails(false);
                    setSubscriptionPlan(false)
                    setPaymentDetails(true);
                    const userID = response.data.content.userID;

                    // For sign-up
                    window.dataLayer.push({
                        'event': 'user_signed_up',
                        'user_id': userID,
                        'user_email': email
                    });

                    completeRegistration();
                } else {
                    toast("Something went wrong While authenticating", { type: "error" });
                }
            } catch (error) {
                console.log("error", error);
                if (error?.response?.data?.error?.validationErrors.length) {
                    //Validation Errors
                    for (let index = 0; index < error?.response?.data?.error?.validationErrors.length; index++) {
                        const err = error?.response?.data?.error?.validationErrors[index];
                        console.log(err.msg);
                        toast.warn(err.msg);
                    }
                } else {
                    toast.error(`${error.message}`);
                }
            }
        }

        // Data Layer Push for Begin Checkout event
        window.dataLayer.push({
            'event': 'begin_checkout',
            'ecommerce': {
                'user_id': userData?.userID ? userData?.userID : 'Guest User',
                'user_email': userData?.email ? userData?.email : 'Guest User',
                'items': [
                    {
                        'item_name': DrawData.drawName,
                        'item_id': DrawData.drawID,
                        'price': amount,
                        'item_brand': 'Sport',
                        'item_category': DrawData.drawCategoryID === 1 ? 'Fixed Cash Prize' : DrawData.drawCategoryID === 2 ? 'Split Cash Prize' : DrawData.drawCategoryID === 3 ? 'Experiential' : DrawData.drawCategoryID === 4 ? 'Hybrid' : '',
                        'item_variant': !isoneoff ? 'Subscription' : 'One-off',
                        'quantity': entries
                    }
                ]
            }
        });

        initiateCheckout(currency, value);
    }

    async function toPaymentDetailsWithManCity() {
        // setPaymentDetails(true);
        // const fn = fullName.split(" ");

        // The form is valid, you can proceed with form submission here
        // ...
        console.log('Form is valid. Proceed with submission.');
        console.log('userManCityData==========', userManCityData);
        //   }
        try {
            const response = await toast.promise(axios.post(
                APIURL + "/users",
                {
                    "userFirstName": userManCityData.firstName,
                    "userSurname": userManCityData.lastName,
                    "userEmail": userManCityData.email,
                    "userPassword": password,
                    "userDateOfBirth": moment(userManCityData.dob).format("YYYY-MM-DD"),
                    "userAddressLine1": userManCityData.addressLine1,
                    "userAddressLine2": userManCityData.addressLine2,
                    "userAddressLine3": userManCityData.addressLine3,
                    "userAddressCountryLocaleID": userManCityData.countryCode,
                    "userAddressPostcode": userManCityData.zip,
                    "userRoleID": 1,
                    "userGender": userManCityData.gender,
                    "userLanguageID": 51,
                    "userAccountApproved": 1,
                    "userOptInMarketingPFW": 0,
                    "userMeta": {
                        "Website": "Manchester City F.C"
                    },
                    "siteID": siteId
                },
                {
                    withCredentials: true,
                    credentials: "same-origin"
                }
            ), {
                pending: 'Please wait...',
            }
            );

            if (response.data.status === true && response.data.error === null) {
                toast("Successfully Created Account", { type: "success" });
                secureLocalStorage.setItem("LogObject", { userID: response.data.content.userID, email: userManCityData.email, loggedin: true })
                setUserId(response.data.content.userID);
                setEmail(userManCityData.email);
                setFullName(userManCityData.firstName + ' ' + userManCityData.lastName);
                setPersonalDetails(false);
                setSubscriptionPlan(false)
                setPaymentDetails(true);
                setPage("3");
                try{
                    const body = {
                        manCityUserEmail: userManCityData.email,
                        manCityUserFistName: userManCityData.firstName,
                        manCityUserLastName: userManCityData.lastName,
                        manCityUserDob: moment(userManCityData.dob).format("YYYY-MM-DD"),
                    }
                    const manCityResgisterationResp = await axios.post(
                        APIURL + "/users/man-city-signup",
                        body,
                        {
                            withCredentials: true,
                            credentials: "same-origin"
                        }
                        );
                        console.log('manCityResgisterationResp===========>>>>>>>>>>>', manCityResgisterationResp);
                }
                catch(error){
                    console.log(`Failed to add new user to Man-City server: ${error}`);
                }
            }
            else {
                toast("Something went wrong While authenticating", { type: "error" });
            }  
        } catch (error) {
            console.log(error);
            toast.error(`${error.message}`);
        }
    }

    const isNumeric = (str) => {
        return /^\d+$/.test(str);
    };

    const currentDate = new Date();
    const userDateOfBirth = new Date(userManCityData.dob);
    const userAge = currentDate.getFullYear() - userDateOfBirth.getFullYear();

    const handleManCitySignUp = async () => {
        console.log('userManCityData', userManCityData);
        if (!userManCityData.firstName || !userManCityData.lastName) {
            toast.error(`Please Enter Your First and Last Name`);
        } else if (userManCityData.email.trim().length === 0 || !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(userManCityData.email)) {
            toast.error(`Please Enter a Valid Email`);
        } else if (!userManCityData.dob) {
            toast.error(`Please Enter a Date of Birth`);
        } else if (userAge < 18) {
            toast.error(`You must be 18 years or older to proceed`);
        } else {
            try {
                const response = await toast.promise(apiClient.post(
                    `${process.env.REACT_APP_API_URL}/users/email`,
                    {
                        userEmail: userManCityData.email,
                        userPassword: password
                    },
                    {
                        withCredentials: true,
                        credentials: "same-origin"
                    }
                ), {
                    pending: 'Please wait...',
                }
                );
                if (response.data.status === true && response.data.error === null && response.data.content.length != 0) {
                    toast("You already have an account on City in the Community kindly login", { type: "success", autoClose: false });
                } else {
                    console.log('userManCityData', userManCityData);
                    setPersonalDetails(false);
                    setPaymentDetails(false);
                    setCityModal(false);
                    setSubscriptionPlan(true);
                    setIsCityModalUsed(true);
                    setPage("2");
                }
            } catch (error) {
                console.log(error?.response?.data?.error);
                if (error?.response?.data?.error?.validationErrors.length) {
                    //Validation Errors
                    for (let index = 0; index < error?.response?.data?.error?.validationErrors.length; index++) {
                        const err = error?.response?.data?.error?.validationErrors[index];
                        toast.warn(err.msg);
                    }
                } else {
                    toast.error(`${error.message}`);
                }
            }
        }
    }

    const checkConsents = () => {
        if (!consent3) {
            toast("Please read and accept Terms & Conditions", { type: "error" });
        }
    }

    const today = new Date();
    const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

    const [page, setPage] = useState("1");
    const nextPageNumber = (pageNumber) => {
        // switch (pageNumber) {
        // case "1":
        //     setCityModal(true);
        //     setPersonalDetails(false);
        //     setPaymentDetails(false);
        //     setSubscriptionPlan(false);
        //     setIsCityModalUsed(false);
        //     setPage("1");
        //     break;
        // case "2":
        //     setPersonalDetails(false);
        //     setPaymentDetails(false);
        //     setCityModal(false);
        //     setSubscriptionPlan(true);
        //     setIsCityModalUsed(true);
        //     setPage("2");
        //     break;
        // case "3":
        //     alert("Ooops! Seems like you did not fill the form.");
        //     break;
        // default:
        //     setPage("1");
        // }
    };

    return (
        <>
            <Helmet>
                <title>Signup for Man City Draw | Your Gateway to Exciting Prizes</title>
                <meta name="description" content="Create an account to participate in draws, win amazing prizes, and support City in the Community's mission. Your journey to making a difference begins here!" />
            </Helmet>
            {!personalDetails && !paymentDetails && !SubscriptionPlan && !cityModal ?
                <section className='signup-section font-face-sh'>
                    <div className='container'>
                        {/* <MultiStepProgressBar page={1} onPageNumberClick={nextPageNumber} /> */}
                        <svg viewBox="0 0 1425 358" xmlns="http://www.w3.org/2000/svg" className="StepSubscriptionPlan_topBackground position-fixed top-0 start-0" style={{ width: "100vw", height: "25.12281vw" }}><path d="M1425 .84v207.845c0 197.772-1425 200.397-1425 0V.42l1425 .42z" fill="#001838" fillRule="nonzero" /></svg>
                        <div className='text-center position-relative mt-5'>
                        <Link to={'/'}>
                            <img className="logo" src={Logo} style={{ maxHeight: 70 }} />
                        </Link>
                        </div>
                        <div className='text-center mt-4 mb-5'>
                            {/* <strong className="l-title text-white mt-5 position-relative">Step</strong> */}
                        </div>
                        <div>
                            <MultiStepProgressBar page={page} onPageNumberClick={nextPageNumber} />
                        </div>
                        <div className="login-box mx-auto mt-4" style={{ maxWidth: 480 }}>
                            <div className="login-header text-center pt-1 position-relative">
                                {/* <Link to={"/"} >
                                    <img className="logo mb-3" src={LogoWhites} style={{ maxHeight: 70 }} />
                                </Link> */}
                                <h3 className="title text-center text-white fs-22 mb-1 pb-1 l-title mt-4" id="title" style={{ display: 'block' }}>Create Your Account</h3>
                                <h5 className="subtitle text-center mb-4" id="toggleTypeContainer" style={{ display: 'block', fontSize: 15 }}>
                                    <span id="hint" style={{ display: 'inline', color: '#fff' }}>Already have an account?</span>
                                    <span className="subtitle-link" id="login-instead" > <Link style={{ color: '#E6FF00' }} className='text-decoration-none branding-1' to={"/login"}> Log in</Link></span>
                                </h5>
                            </div>
                            <div id="emailPassword" className="bg-white signup-buttons position-relative" style={{ maxWidth: 480, margin: '0 auto', padding: '40px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.16)', border: '1px solid #ccc', borderRadius: 15 }}>
                                <ul className='checkbox-list list-unstyled'>
                                    <li className='checkbox-item pt-4 pb-0'>
                                        <input type="email" id="email" onChange={(event) => setEmail(event.target.value)} placeholder="Email address" className="input" style={{ display: 'block' }} />
                                    </li>
                                    <li className='checkbox-item pt-4 pb-2'>
                                    <input type="password" id="password" onChange={(event) => setPassword(event.target.value)} placeholder="Password" className="input" style={{ display: 'block' }} /></li>
                                </ul>
                            <button type="button" onClick={() => CheckValidity()} id="btn-signup" className="cta-large-button btn bg-branding-1 border-0 w-100 fs-5 font-face-sh-bold rounded-pill  py-2 mt-3 mb-4 lh-2 desktop-btn text-light" style={{ display: 'block', height: 48 }}>Sign up with email</button>
                            <div className="d-flex justify-content-center">
                                <div className="pfw-awesome-divider" data-label="or"></div>
                            </div>
                            <button type="button" onClick={() => setCityModal(true)} id="btn-signup" className="cta-large-button btn bg-branding-1 border-0 w-100 fs-5 font-face-sh-bold rounded-pill  py-2 mt-4 mb-2 lh-2 desktop-btn text-light" style={{ display: 'block', height: 48 }}>Sign up with City in the Community</button>
                        </div>
                        <p className="terms mt-4 m-auto" id="terms" style={{maxWidth : 360}}>By signing in to <span id="product-name">City in the Community</span>, you agree to our <Link className="terms-link branding-1-hover" id="terms-link" to={'/terms-conditions'} rel="noopener noreferrer">Terms &amp; Conditions</Link> and <Link className="terms-link branding-1-hover" id="privacy-policy-link" to={'/privacy-policy'} rel="noopener noreferrer">Privacy Policy</Link></p>
                    </div>
                </div>
                </section>
                :
    cityModal ?
        <section className="container">
            {/* <form> */}

            <div id="userSignup" style={{ display: "block", marginTop: "30px" }}>
                {/* <div className="login-header text-center pt-1" >
                    <Link to={"/"} >
                        <img className="logo mb-3" src={LogoWhite} style={{ maxHeight: 70 }} />
                    </Link>
                </div> */}
                <svg viewBox="0 0 1425 358" xmlns="http://www.w3.org/2000/svg" className="StepSubscriptionPlan_topBackground position-fixed top-0 start-0" style={{ width: "100vw", height: "25.12281vw" }}><path d="M1425 .84v207.845c0 197.772-1425 200.397-1425 0V.42l1425 .42z" fill="#001838" fillRule="nonzero" /></svg>
                        <div className='text-center position-relative mt-5'>
                        <Link to={'/'}>
                            <img className="logo" src={Logo} style={{ maxHeight: 70 }} />
                        </Link>
                        </div>
                        <div className='text-center mt-4 mb-5'>
                            {/* <strong className="l-title text-white mt-5 position-relative">Step</strong> */}
                        </div>
                        <div>
                            <MultiStepProgressBar page={page} onPageNumberClick={nextPageNumber} />
                        </div>


                <div className='form-checkbox rounded-3 signup-forms position-relative bg-white mt-5' style={{ maxWidth: 560, margin: '0 auto', padding: '40px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.16)', border: '1px solid #ccc', borderRadius: 15 }}>
                    <ul className='checkbox-list list-unstyled'>
                        <li className='checkbox-item pt-4 pb-0'>
                            <input type="text" name="firstName" id="firstName" placeholder="First Name" onChange={(event) =>
                                setUserManCityData({ ...userManCityData, firstName: event.target.value })
                            } className="input mb-2" />
                        </li>
                        <li className='checkbox-item pt-4 pb-0'>
                            <input type="text" name="lastName" id="lastName" placeholder="Last Name" onChange={(event) =>
                                setUserManCityData({ ...userManCityData, lastName: event.target.value })
                            } className="input mb-2" />
                        </li>


                        <li className='checkbox-item pt-4 pb-0'>
                            <input type="text" name="email" id="email" placeholder="Email" onChange={(event) =>
                                setUserManCityData({ ...userManCityData, email: event.target.value })
                            } className="input mb-2" />
                        </li>
                        <li className='checkbox-item pt-4 pb-0'>
                            {/* <input type="text" name="lastName" id="lastName" placeholder="Last Name" onChange={(event) =>
                                            setUserManCityData({ ...userManCityData, lastName: event.target.value })
                                        } className="input mb-3" /> */}

                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                name="dateOfBirth"
                                id="dateOfBirth"
                                placeholderText="Date of birth (DD/MM/YYYY)"
                                className="input mb-2"
                                dateFormat="yyyy-MM-dd"
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={90}
                                maxDate={maxDate}
                            />

                        </li>
                    </ul>
                </div>

                {/* <div className="row" style={{gap: "50px"}}>


                                <div className="col">
                                    <input
                                    type="text"
                                    id="firstName"
                                    onChange={(event) =>
                                        setUserManCityData({ ...userManCityData, firstName: event.target.value })
                                    }
                                    placeholder="First Name"
                                    className="input"
                                    style={{ display: "block" }}
                                    />
                                </div>
                                <div className="col">
                                    <input
                                    type="text"
                                    id="lastName"
                                    onChange={(event) =>
                                        setUserManCityData({ ...userManCityData, lastName: event.target.value })
                                    }
                                    placeholder="Last Name"
                                    className="input"
                                    style={{ display: "block" }}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{gap: "50px"}}>
                                <div className="col">
                                    <input
                                    type="email"
                                    id="email"
                                    onChange={(event) =>
                                        setUserManCityData({ ...userManCityData, email: event.target.value })
                                    }
                                    placeholder="Email"
                                    className="input"
                                    style={{ display: "block" }}
                                    />
                                </div>
                                <div className="col">
                                    <input
                                    type="text"
                                    id="username"
                                    onChange={(event) =>
                                        setUserManCityData({ ...userManCityData, username: event.target.value })
                                    }
                                    placeholder="Username"
                                    className="input"
                                    style={{ display: "block" }}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{gap: "50px"}}>
                                <div className="col">
                                    <input
                                    type="text"
                                    id="countryCode"
                                    onChange={(event) =>
                                        setUserManCityData({
                                        ...userManCityData,
                                        countryCode: event.target.value,
                                        })
                                    }
                                    placeholder="Country Code"
                                    className="input"
                                    style={{ display: "block" }}
                                    />
                                </div>
                                <div className="col">
                                    <input
                                    type="text"
                                    id="mobile"
                                    onChange={(event) =>
                                        setUserManCityData({ ...userManCityData, mobile: event.target.value })
                                    }
                                    placeholder="Mobile"
                                    className="input"
                                    style={{ display: "block" }}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{gap: "50px"}}>
                                <div className="col">
                                    <input
                                    type="text"
                                    id="addressLine1"
                                    onChange={(event) =>
                                        setUserManCityData({
                                        ...userManCityData,
                                        addressLine1: event.target.value,
                                        })
                                    }
                                    placeholder="Address Line 1"
                                    className="input"
                                    style={{ display: "block" }}
                                    />
                                </div>
                                <div className="col">
                                    <input
                                    type="text"
                                    id="addressLine2"
                                    onChange={(event) =>
                                        setUserManCityData({
                                        ...userManCityData,
                                        addressLine2: event.target.value,
                                        })
                                    }
                                    placeholder="Address Line 2"
                                    className="input"
                                    style={{ display: "block" }}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{gap: "50px"}}>
                                <div className="col">
                                    <input
                                    type="text"
                                    id="addressLine3"
                                    onChange={(event) =>
                                        setUserManCityData({
                                        ...userManCityData,
                                        addressLine3: event.target.value,
                                        })
                                    }
                                    placeholder="Address Line 3"
                                    className="input"
                                    style={{ display: "block" }}
                                    />
                                </div>
                                <div className="col">
                                    <input
                                    type="text"
                                    id="city"
                                    onChange={(event) =>
                                        setUserManCityData({ ...userManCityData, city: event.target.value })
                                    }
                                    placeholder="City"
                                    className="input"
                                    style={{ display: "block" }}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{gap: "50px"}}>
                                <div className="col">
                                    <input
                                    type="text"
                                    id="zip"
                                    onChange={(event) =>
                                        setUserManCityData({ ...userManCityData, zip: event.target.value })
                                    }
                                    placeholder="ZIP Code"
                                    className="input"
                                    style={{ display: "block" }}
                                    />
                                </div>
                                <div className="col">
                                    <select
                                        id="gender"
                                        onChange={(event) => setUserManCityData({ ...userManCityData, gender: event.target.value })}
                                        className="form-select"
                                        style={{ display: 'block' }}
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="1">Male</option>
                                        <option value="2">Female</option>
                                        <option value="3">Other</option>
                                    </select>
                                </div>

                            </div>*/}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button onClick={handleManCitySignUp} className="cta-large-button btn bg-branding-1 border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn  position-relative d-flex align-items-center justify-content-center text-light"
                        style={{ width: "200px", height: 48 }}>
                        <span>{isoneoff ? "Buy Now" : "Subscribe"}</span>
                        {/* <img className='position-absolute top-0 bottom-0 my-auto' src={NextArrow} style={{ height: 40, right: 4 }} /> */}
                    </button>
                </div>
            </div>
            {/* </form> */}
        </section>
        :
        SubscriptionPlan ?
            <section className='subscription-plan font-face-sh' id="subscription-signup">
                <div className='subscription-plan-container mx-auto'>
                    
                    <svg viewBox="0 0 1425 358" xmlns="http://www.w3.org/2000/svg" className="StepSubscriptionPlan_topBackground position-fixed top-0 start-0" style={{ width: "100vw", height: "25.12281vw" }}><path d="M1425 .84v207.845c0 197.772-1425 200.397-1425 0V.42l1425 .42z" fill="#001838" fillRule="nonzero" /></svg>
                    <div className='text-center position-relative mt-5'>
                    <Link to={'/'}>
                        <img className="logo" src={Logo} style={{ maxHeight: 70 }} />
                    </Link>
                    </div>
                    <div className='text-center mt-4 mb-5'>
                        {/* <strong className="l-title text-white mt-5 position-relative">Step</strong> */}
                    </div>
                    <div>
                        <MultiStepProgressBar page={page} onPageNumberClick={nextPageNumber} />
                    </div>
                    <div className={Freemodal ? 'steps-subscription-plan position-relative pt-5 d-none' : 'steps-subscription-plan position-relative pt-5 d-block'} style={{ zIndex: 999 }}>
                        <h2 className='step-subscription-plan-title text-light font-face-sh-bold display-6 text-center mb-3'>Pick your plan</h2>
                        <div className='step-subscription-plan-description'>
                            <h3 className='step-subscription-plan-description_title text-light font-face-sh-bold fs-5 text-center mb-0'>How many entries do you want for each draw?</h3>
                            <p className='step-subscription-plan-description_text text-light text-center'>You can cancel or change your plan at any time.</p>
                        </div>
                        <div className='step-subscription-plan-selector pt-5 container mt-3'>
                            <ul className='d-flex unstyled list-unstyled gap-3'>
                                <li className={isActive1 ? 'active' : ''} onClick={() => handleClick('first', 5, 1)}>
                                    <div className='ticket-title-sec px-3 py-5 position-relative' style={{ borderRadius: 6, filter: 'drop-shadow(0 4px 28px rgba(0,0,0,.12))' }}>
                                        <h4 className='fs-5 text-center mb-4 mb-0' style={{ color: '#302d51', borderBottom: '2px solid #001838', paddingBottom: 15 }}>1 Ticket</h4>
                                        <div className='price text-center posiion-relative'>
                                            <span className='display-6'>£</span><span className='display-4'>5</span><br />{isoneoff ? " " : <span className='branding-2 fs-6'>per month</span>}
                                        </div>

                                    </div>
                                </li>
                                <li className={isActive2 ? 'active' : ''} onClick={() => handleClick('second', 10, 2)}>

                                    <div className='ticket-title-sec px-3 py-5 position-relative' style={{ borderRadius: 6, filter: 'drop-shadow(0 4px 28px rgba(0,0,0,.12))' }}>
                                        <h4 className='fs-5 text-center mb-4 mb-0' style={{ color: '#302d51', borderBottom: '2px solid #001838', paddingBottom: 15 }}>2 Tickets</h4>

                                        <div className='price text-center posiion-relative'>
                                            <span className='display-6'>£</span><span className='display-4'>10</span><br />{isoneoff ? " " : <span className='branding-2 fs-6'>per month</span>}
                                        </div>

                                    </div>

                                </li>
                                <li className={isActive3 ? 'position-relative active' : 'position-relative'} onClick={() => handleClick('third', 25, 5)}>
                                    <div className='subscription-plan-emphasis text-white d-inline-block lh-normal position-absolute start-0 end-0 m-auto' style={{ background: '#6da9c8', fontSize: 14, padding: '7px 6px 7px 7px', width: 150, top: -40, zIndex: 999, textAlign: "center", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>Popular</div>
                                    <div className='ticket-title-sec px-3 py-5 position-relative' style={{ borderRadius: 6, filter: 'drop-shadow(0 4px 28px rgba(0,0,0,.12))' }}>
                                        <h4 className='fs-5 text-center mb-4 mb-0' style={{ color: '#302d51', borderBottom: '2px solid #001838', paddingBottom: 15 }}>5 Tickets</h4>

                                        <div className='price text-center posiion-relative'>
                                            <span className='display-6'>£</span><span className='display-4'>25</span><br />{isoneoff ? " " : <span className='branding-2 fs-6'>per month</span>}
                                        </div>

                                    </div>

                                </li>
                                <li className={isActive4 ? 'active' : ''} onClick={() => handleClick('fourth', 50, 10)}>
                                    <div className='ticket-title-sec px-3 py-5 position-relative' style={{ borderRadius: 6, filter: 'drop-shadow(0 4px 28px rgba(0,0,0,.12))' }}>
                                        <h4 className='fs-5 text-center mb-4 mb-0' style={{ color: '#302d51', borderBottom: '2px solid #001838', paddingBottom: 15 }}>10 Tickets</h4>

                                        <div className='price text-center posiion-relative'>
                                            <span className='display-6'>£</span><span className='display-4'>50</span><br />{isoneoff ? " " : <span className='branding-2 fs-6'>per month</span>}
                                        </div>

                                    </div>

                                </li>
                            </ul>
                        </div>
                        <div className='subscription-plan-selector-amountarea bg-white p-3 mt-4 container' style={{ borderRadius: 6, boxShadow: '0px 0px 3px #38276640' }}>
                            <div className='row align-items-center'>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='subscription-plan-price d-flex justify-content-center'>
                                        <div className='subscription-plan-price_currency branding-1 fs-2 font-face-sh-bold mt-4 me-1'>£</div>
                                        <div className='subscription-plan-price_amount branding-1 display-3 font-face-sh-bold'>{amount}</div>
                                        <div className='subscription-plan-price_term text-black lh-1 mt-3 ms-1' style={{ fontSize: 12 }}>{isoneoff ? "" : <>per<br />month</>}</div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='subscription-plan-selector-entries d-flex align-items-center'>
                                        <div className='draw-entries-icon position-relative' style={{ backgroundColor: '#f3f2fb', width: 52, height: 52, borderRadius: '50%' }}></div>
                                        <ul className='unstyled list-unstyled ul-tick ms-3 mb-0' style={{ fontSize: 12 }}>
                                            <li>Auto-entry into every draw</li>
                                            <li>Regular charitable support</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='step-subscription-button text-center mb-4 d-flex justify-content-center'>

                            {false ? (
                                <>
                                    <button
                                        onClick={handleShow}
                                        className="cta-large-button btn bg-branding-1 border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn  position-relative d-flex align-items-center justify-content-center text-light"
                                        style={{ width: "200px", height: 48 }}
                                    >
                                        <span>{isoneoff ? "Buy Now" : "Subscribe"}</span>
                                    </button>
                                    <Modal show={showModal} onHide={handleClose} dialogClassName="custom-modal-startShow">
                                        <Modal.Header closeButton>
                                            <Modal.Title>Draw has not started yet</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='text-center'>
                                                <img className='mx-auto w-50 mb-3' src={require('../images/mancity-horizontal-dark.png')} style={{ width: 66 }} />
                                                <p className='fs-3'>This draw will start at <span className="branding-2">{DrawData?.drawEndDate ? Moment(DrawData.drawStartDate).format('MMMM Do, [at] ha') : "-----, /--/--/-- 00:00PM"}</span></p>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <span variant="secondary" onClick={handleClose}>
                                                Close
                                            </span>
                                        </Modal.Footer>
                                    </Modal>
                                </>

                            ) : (
                                <button onClick={() => toPersonalDetails(true)} className="cta-large-button btn bg-branding-1 border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn  position-relative d-flex align-items-center justify-content-center text-light"
                                    style={{ width: "200px", height: 48 }}>
                                    <span>{isoneoff ? "Buy Now" : "Subscribe"}</span>
                                    {/* <img className='position-absolute top-0 bottom-0 my-auto' src={NextArrow} style={{ height: 40, right: 4 }} /> */}
                                </button>
                            )
                            }
                        </div>
                        <div className="form-check form-switch d-flex justify-content-center pt-0">
                            <input checked={isoneoff} onChange={() => setIsoneoff(!isoneoff)} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" style={{ marginRight: 5 }} />
                            <label className="form-check-label text-dark" htmlFor="flexSwitchCheckDefault">{!isoneoff ? " Tick to make a one-off entry into the next draw" : " Untick to make a Subscription entry into the next draw"}</label>
                        </div>
                        <div className='terms-cond mt-3 text-center mb-3'>
                            <p className='m-0 branding-1'>Alternative entry routes available, <Link className='text-decoration-none branding-2 branding-5' to={'/terms-conditions'}>click here</Link> for terms.</p>
                        </div>
                    </div>
                    <div className={Freemodal ? 'free-entries-modal d-block position-relative' : 'free-entries-modal d-none'}>
                        <div className='free-entry-modal_title_block mb-5 pb-5'>
                            <img className='cross bg-white rounded-3 p-1 position-absolute end-0 top-0' src={Cross} style={{ width: 56, boxShadow: '0 4px 16px rgb(20 19 38 / 30%)', cursor: 'pointer' }} onClick={() => setFreemodal(!Freemodal)} />
                            <h2 className='free-entry-modal_title text-light font-face-sh-bold display-6 text-center mb-3 mt-5'>Free Prize Draw</h2>
                            <p className='free-entry-modal_text text-light text-center fs-5 mx-auto mt-5' style={{ maxWidth: 375 }}>While we encourage contributions to achieve our mission, we understand not everyone may be in a position to contribute.</p>
                        </div>
                        <div className='free-entry-modal_description_block mx-auto mt-5 p-4 bg-white d-flex' style={{ maxWidth: 375 }}>
                            <div className='free-entry-icon'>
                                <img src={FreeEntryIcon} style={{ width: 24 }} />
                            </div>
                            <div className='free-entry-description ps-4 fs-14 lh-1'>
                                <p>For 5 non-donation entries to the prize draw please post the following information:</p>
                                <p>* First Name<br />
                                    * Last Name<br />
                                    * Email</p>
                                <p>to: City in the Community<br />Matchplay Entertainment Ltd,<br />483 Green Lanes, N13 4BS, London, UK<br /><br />You will be entered into the prize draws for the month in which we receive your post entries.<br /><br />Note: This will not result in any contributions to the Foundation</p>
                                <div className='emoji-icon text-center pe-5 mt-4 pt-2'>
                                    <img className='mx-auto' src={require('../images/emoji_icon.png')} style={{ width: 66 }} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            :
            personalDetails ?
                <section className='personal-details font-face-sh py-5' id="personal-details">
                    <div className='text-center' style={{ fontSize: "30px" }}>
                        <strong>STEP</strong>
                    </div>
                    <div>
                        <MultiStepProgressBar page={page} onPageNumberClick={nextPageNumber} />
                    </div>
                    <div className='subscription-plan-container mx-auto' style={{ maxWidth: 780 }}>
                        <div className='row'>
                            <div className='col-lg-7 col-md-7 col-sm-12 step-user-details pe-5'>
                                {/* <div className='step-plan_step branding-1 rounded-pill text-center mx-auto' style={{ backgroundColor: '#f7f7fd', width: 112, height: 45, lineHeight: '45px' }}><strong>Step 1 of 2</strong></div> */}
                                <h2 className='text-black font-face-sh-bold mt-3 text-center'>Personal details</h2>
                                <p className='text-center' style={{ color: '#808080', fontSize: '18px' }}>We just need to capture a few personal details to get you going.</p>
                                <form method="post">
                                    <input type="text" name="fullName" id="fullName" placeholder="Full Name" onChange={(event) => setFullName(event.target.value)} className="input mb-3" />
                                    <PhoneInput
                                        placeholder="+44 0000000000"
                                        defaultCountry="GB"
                                        countryCallingCodeEditable={false}
                                        international
                                        value={`${userPhoneNumber}`}
                                        name="userPhoneNumber"
                                        className="rounded-pill align-self-center w-100 px-3 p-3 bg-f2 input  border-0"
                                        onChange={(e) => setUserPhoneNumber(`${e}`)}
                                    />
                                    {/* <input type="tel" onInput={(event) => dateSet(event.target.value, event)} name="dateOfBirth" id="dateOfBirth" placeholder="Date of birth (DD/MM/YYYY)" className="input mb-3" /> */}
                                    <div className='form-radio-box'>
                                        <label className='mb-2 ps-3' style={{ color: '#808080', fontSize: 14 }}>Gender</label>
                                        <ul className='radio-list list-unstyled d-flex gap-3'>
                                            <li className='radio-item'>
                                                <label className='input-radio'>
                                                    <input type="radio" name="gender" value="1" defaultChecked onClick={() => setGender(1)} />
                                                    <span className='input-radio-label'>Male</span>
                                                </label>
                                            </li>
                                            <li className='radio-item'>
                                                <label className='input-radio'>
                                                    <input type="radio" name="gender" value="2" onClick={() => setGender(2)} />
                                                    <span className='input-radio-label'>Female</span>
                                                </label>
                                            </li>
                                            <li className='radio-item'>
                                                <label className='input-radio'>
                                                    <input type="radio" name="gender" value="3" onClick={() => setGender(3)} />
                                                    <span className='input-radio-label'>Other</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                                <div className='step-subscription-button text-center mb-5 d-flex justify-content-center'>
                                    <button onClick={() => toPaymentDetails(true)} className="cta-large-button btn bg-branding-1 border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn  position-relative d-flex align-items-center justify-content-center text-light"
                                        style={{ width: "200px", height: 48 }}>
                                        <span>{isoneoff ? "Buy Now" : "Subscribe"}</span>
                                        {/* <img className='position-absolute top-0 bottom-0 my-auto' src={NextArrow} style={{ height: 40, right: 4 }} /> */}
                                    </button>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-5 col-sm-12 step-user-details-info mt-5 pt-4 pe-0'>
                                <div className='user-details-info bg-branding-1 rounded-4' style={{ padding: '35px 35px 19px', boxShadow: '0 15px 50px -30px #000000' }}>
                                    <h3 className='text-light fs-5 mb-4'>Summary</h3>
                                    <div className='summary-info-impact pb-3'>
                                        <div className='summary-info-impact_title text-light d-flex align-items-center' style={{ fontSize: 18 }}>{entries} chances to win</div>
                                        <div className='summary-info-impact_period lh-1' style={{ color: 'rgb(255,255,255,0.7)', paddingLeft: 35 }}>every draw</div>
                                    </div>
                                    <hr className='bg-light w-100 border-0' style={{ height: 1, opacity: .1 }} />
                                    <div className='summary-info-price d-flex align-items-center pb-2'>
                                        <span className='summary-info-price_amount text-light display-4'>£ {amount}</span>
                                        <span className='summary-info-price_period ps-3' style={{ color: 'rgb(255,255,255,0.7)' }}>{isoneoff ? "" : "per month"}</span>
                                    </div>
                                    <hr className='bg-light w-100 border-0' style={{ height: 1, opacity: .1 }} />
                                    <p className='summary-info-upgrade-text m-0' style={{ color: 'rgb(255,255,255,0.7)' }}>Upgrade or cancel anytime</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                : paymentDetails ?
                    <section className='personal-details font-face-sh py-5' id="personal-details">
                        <div className='text-center position-relative'>
                        <Link to={'/'}>
                            <img className="logo" src={LogoDark} style={{ maxHeight: 70 }} />
                        </Link>
                        </div>
                        <div className='text-center mt-4 mb-5'>
                            {/* <strong className="l-title text-white mt-5 position-relative">Step</strong> */}
                        </div>
                        <div>
                            <MultiStepProgressBar page={page} onPageNumberClick={nextPageNumber} />
                        </div>
                        <p className='text-center py-2' style={{ color: '#001838', fontSize: '18px', marginTop: "10px" }}>We’ll take your amount </p>
                        <div className='subscription-plan-container mx-auto mt-4' style={{ maxWidth: 780 }}>
                            <div className='row'>
                                
                                <div className='col-lg-7 col-md-7 col-sm-12 step-user-details pe-5' >
                                    {/* <div className='step-plan_step branding-1 rounded-pill text-center mx-auto' style={{ backgroundColor: '#f7f7fd', width: 112, height: 45, lineHeight: '45px' }}><strong>Step 2 of 2</strong></div> */}
                                    {/* <h2 className='text-black font-face-sh-bold mt-3 text-center'>Payment details</h2> */}

                                    <form method="post">
                                        {/* <label className='mb-2 ps-3' style={{color: '#808080', fontSize: 15}}>Payment method</label> */}
                                        {/* <div className='text-light bg-branding-1 rounded-pill text-center mb-4' style={{width: 112, height: 45, lineHeight: '45px', fontSize: 14}}>Bank card</div> */}
                                        {/* <input type="text" name="cardnumber" id="cardnumber" placeholder="Card number" className="input mb-3" /> */}
                                        <div className='form-checkbox'>
                                            <ul className='checkbox-list list-unstyled'>
                                                <li className='checkbox-item mb-4'>
                                                    <label className='input-checkbox d-flex'>
                                                        <input checked={consent1} onChange={() => setConsent1(!consent1)} type="checkbox" name="consentId1" value="1" />
                                                        <span className='input-checkbox_check'></span>
                                                        <span className='input-checkbox-label'>Yes, I’d like to receive updates about City in the Community via email.</span>
                                                    </label>
                                                </li>
                                                <li className='checkbox-item'>
                                                    <label className='input-checkbox d-flex'>
                                                        <input checked={consent2} onChange={() => setConsent2(!consent2)} type="checkbox" name="consentId2" value="10" />
                                                        <span className='input-checkbox_check'></span>
                                                        <span className='input-checkbox-label'>Yes, City in the Community can contact me about their work via email.</span>
                                                    </label>
                                                </li>
                                                <li className='checkbox-item'>
                                                    <label className='step-payment-details_consent_description mt-5 input-checkbox d-flex' style={{ backgroundColor: 'rgb(198,29,35,.1)', color: '#302d51', padding: '14px 18px', borderRadius: 20, fontSize: 13 }}>
                                                        <input checked={consent3} onChange={() => setConsent3(!consent3)} type="checkbox" name="consentId3" value="10" />
                                                        <span className='input-checkbox_check'></span>
                                                        <span>By clicking on Confirm you agree to City in the Community<Link to={"/terms-conditions"} style={{ color: '#302d51' }}>Terms & Conditions of Use</Link>, <Link to={"/privacy-policy"} style={{ color: '#302d51' }}>Privacy Policy</Link> and that you <strong>are at least 18 years old.</strong></span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </form>
                                    {!consent3 ?
                                        <button onClick={checkConsents} className="cta-large-button btn bg-branding-1 border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn  position-relative d-flex align-items-center justify-content-center text-light"
                                        style={{ width: "100%", height: 48 }}>
                                            Pay now</button>
                                        :
                                        <>
                                            <button onClick={() => { setStripe(true); setOneShow(true) }} className="cta-large-button btn bg-branding-1 border-0 rounded-pill py-2 mt-5 lh-2 desktop-btn  position-relative d-flex align-items-center justify-content-center text-light"
                                    style={{ width: "100%", height: 48 }}>
                                                Pay now</button>
                                        </>
                                    }
                                </div>
                                <div className='col-lg-5 col-md-5 col-sm-12 step-user-details-info pt-0 pe-0'>
                                    <div className='user-details-info bg-branding-1 rounded-4' style={{ padding: '35px 35px 19px', boxShadow: '0 15px 50px -30px #000000' }}>
                                        <h3 className='text-light fs-5 mb-4'>Summary</h3>
                                        <div className='summary-info-impact pb-3'>
                                            <div className='summary-info-impact_title text-light d-flex align-items-center' style={{ fontSize: 18 }}>{entries} chances to win</div>
                                            <div className='summary-info-impact_period lh-1' style={{ color: 'rgb(255,255,255,0.7)', paddingLeft: 35 }}>every draw</div>
                                        </div>
                                        <hr className='bg-light w-100 border-0' style={{ height: 1, opacity: .1 }} />
                                        <div className='summary-info-price d-flex align-items-center pb-2'>
                                            <span className='summary-info-price_amount text-light display-4'>£ {amount}</span>
                                            <span className='summary-info-price_period ps-3' style={{ color: 'rgb(255,255,255,0.7)' }}>{isoneoff ? "" : "per month"}</span>
                                        </div>
                                        <hr className='bg-light w-100 border-0' style={{ height: 1, opacity: .1 }} />
                                        <p className='summary-info-upgrade-text m-0' style={{ color: 'rgb(255,255,255,0.7)' }}>Upgrade or cancel anytime</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    ""
}
{
    isStripe ?
        <Modal show={oneShow} onHide={() => setOneShow(false)} size="md" centered>
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
                <Elements stripe={stripePromise}>
                    <StripeForm
                        UserID={UserID}
                        show={true}
                        email={email}
                        name={fullName}
                        oneoff={isoneoff}
                        address1='-'
                        address2='-'
                        postcode='-'
                        amount={amount}
                        entries={entries}
                        hideModal={hideModal}
                        navigate={navigate}
                        timeout={timeout}
                        setPage={setPage}
                    />
                </Elements>
            </Modal.Body>
        </Modal>
        : ""
}

<ToastContainer />
        </>
    )
}
