import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import SiteRouter from "./site-router";
import "./fonts/Saints-Regular.woff2";
import "react-toastify/dist/ReactToastify.css";
import "./fonts/Saints-Bold.woff2";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://cf47388095fad0cd8ca99e06bbd4274a@o1326559.ingest.us.sentry.io/4508041421324288",
  integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/mancitydraw\.com/],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SiteRouter />
  </React.StrictMode>
);
