import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { addPaymentInfo, purchase } from "../utils/meta/metaPixelEvent";

const StripeForm = props => {
  const [email, setEmail] = useState("");
  const [oneoff, setOneoff] = useState(false);
  const [amount, setAmount] = useState("");
  const [userID, setUserId] = useState("");
  const [name, setName] = useState("");
  const [entries, setEntries] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const siteId = process.env.REACT_APP_SITE_ID;
  const APIURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    console.log("props====>", props);
    setEmail(props.email);
    setAmount(props.amount);
    setUserId(props.UserID);
    setName(props.name);
    setOneoff(props.oneoff);
    setEntries(props.entries);
  }, []);

  // TODO: Improved handle submit fn
  const handleSubmit2 = stripe => async () => {
    console.log("handleSubmit fired");

    try {
      // Get card element from Stripe Elements
      const cardElement = elements.getElement(CardElement);

      // Create a payment method using the card details
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement
      });

      // Handle error if payment method creation fails
      if (error) {
        console.log("[Error While Creating Element]", error);
      } else {
        // Hide the payment modal after successfully getting payment method
        props.hideModal();

        // Fetch draw data related to the site (you might need this later for transaction details)
        const DrawData = await axios.get(APIURL + "/sites/" + siteId + "/draws");

        // Prepare the checkout data but do not call the API yet
        const checkoutData = {
          amount: amount,
          userEmail: email,
          name: name?.trim() !== "" ? name : "Guest Checkout",
          address1: "-",
          address2: "-",
          postcode: "-",
          stripeToken: paymentMethod.id, // Stripe payment token created earlier
          transactionItems: [
            {
              drawID: DrawData.data.content[0].drawID,
              drawCauseID: DrawData.data.content[0].drawHostCauseID,
              ticketPrice: amount,
              ticketQuantity: entries,
              itemTotal: amount,
              bundleTotal: amount,
              isSubscription: oneoff ? 0 : 1
            }
          ],
          transactionUserID: userID,
          transactionSiteID: siteId,
          product: [
            {
              name: DrawData.data.content[0].drawName, // Draw name
              price: amount,
              description: DrawData.data.content[0].drawName + " Subscription",
              metadata: { email: email },
              data: [
                {
                  id: 6,
                  name: DrawData.data.content[0].drawName,
                  drawID: DrawData.data.content[0].drawID,
                  drawCauseID: DrawData.data.content[0].drawHostCauseID,
                  price: amount,
                  quantity: entries,
                  slug: DrawData.data.content[0].drawPageURL,
                  subscription: !oneoff,
                  subscriptionPeriod: "month",
                  itemTotal: amount
                }
              ]
            }
          ]
        };

        // Make a checkout request to process the payment (only once here)
        const res = await toast.promise(
          axios
            .post(APIURL + `/transactions/stripe3ds/checkout`, checkoutData, {
              withCredentials: true,
              credentials: "same-origin"
            })
            .catch(e => {
              if (e.code === "ERR_BAD_REQUEST") console.log("Something went wrong", e);
              toast.error(e.response.data.message);
            }),
          {
            pending: "Please wait...",
            error: "Something went wrong"
          }
        );

        let transactionID = res.data.transactionID;

        // Check if 3D Secure authentication is required
        if (res.data.actionRequired) {
          // Perform 3D Secure authentication
          const { paymentIntent, error } = await toast.promise(
            stripe.confirmCardPayment(res.data.clientSecret), // Confirm the payment with 3D Secure
            {
              pending: "Please wait...",
              error: "3D Secure Failed"
            }
          );

          // Handle errors during 3D Secure
          if (error) {
            console.log("[Error During 3D Secure]", error);
            // Update transaction status as failed due to 3D Secure failure
            await axios.post(APIURL + `/transactions/stripe3ds/updateStatus`, {
              transactionProcessorID: error.payment_intent.id,
              status: 4 // Status code for failed transaction
            });
            toast("3D Secure Failed", { type: "error" }); // Notify the user of failure
          } else if (paymentIntent.status === "succeeded") {
            // Update transaction status as successful after 3D Secure succeeds
            await axios.post(APIURL + `/transactions/stripe3ds/updateStatus`, {
              transactionProcessorID: paymentIntent.id,
              sendMail: true,
              mailData: res.data.mailData,
              status: 3
            });

            // Notify the user of successful payment

            toast("Payment Done Successfully", { type: "success" });

            // Log the purchase and navigate to the success page
            const currency = "GBP";
            const value = amount;
            purchase(currency, value);
            await props.timeout(2000);
            props.navigate(`/payment-success/${transactionID.toString()}`); // Redirect to success page
          }
        } else {
          // If no 3D Secure required, mark the payment as successful immediately
          toast("Payment Done Successfully", { type: "success" });

          // Log the purchase and navigate to the success page
          const currency = "GBP";
          const value = amount;
          purchase(currency, value);
          await props.timeout(2000);
          props.navigate(`/payment-success/${transactionID.toString()}`); // Redirect to success page
        }
      }
    } catch (error) {
      // Handle general errors in the payment process
      console.error(error);
      return toast("Payment failed!", { type: "error" });
    } finally {
      setIsLoading(false);
      props.hideModal();
    }
  };

  const stripe = useStripe();
  const elements = useElements();

  return (
    <>
      {stripe && stripe._keyMode === "live" ? (
        ""
      ) : (
        <div className="d-flex justify-content-center mb-2">
          <span className="text-white text-center bg-danger px-3 py-1" style={{ marginTop: "-25px" }}>
            Stripe : {stripe?._keyMode} Mode
          </span>
        </div>
      )}
      <h3 className="branding1 text-center">Play Fund Win</h3>
      <span className="text-muted text-center d-flex justify-content-center mt-3">{props.email}</span>
      <div className="p-3 my-3 border rounded-4">
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4"
                }
              },
              invalid: {
                color: "#9e2146"
              }
            }
          }}
        />
      </div>
      <button
        className="bg-branding-1 text-light border-0 shadow rounded-4 px-3 w-100 py-3"
        onClick={handleSubmit2(stripe, elements)}
        // onClick={() => console.log("testing")}
      >

        Pay Now <strong> £{amount} </strong>
      </button>
      <span className="text-muted text-center d-flex justify-content-center mt-3">* 100% Secure & Safe Payment *</span>
    </>
  );
};

export default StripeForm;
